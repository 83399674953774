









import Vue from 'vue';
import LabSim from '@/tasks/components/simulations/LabSim.vue';

export default Vue.extend({
  name: 'LabDisplacementReactions',
  components: {LabSim},
  inheritAttrs: false,
});
