<template>
  <lab-task> <lab-displacement-reactions /></lab-task>
</template>

<script>
import Vue from 'vue';
import LabTask from './labs/LabTask';
import LabDisplacementReactions from '@/tasks/components/simulations/LabDisplacementReactions';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';

export default Vue.extend({
  name: 'DisplacementReactions',
  components: {LabDisplacementReactions, LabTask},
  mixins: [DynamicQuestionMixin()],
});
</script>
